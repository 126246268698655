*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden !important;
}

body {
  min-height: 100vh;
  font-family: "BrandonTextRegular";
  color: $fittly-dark-teal;
  background-color: #faf9f8;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BrandonTextBold";
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.8rem;
}
h3 {
  font-size: 2.4rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.8rem;
}
h6 {
  font-size: 1.6rem;
}

a {
  font-size: 2rem;
}

p {
  font-size: 1.6rem;
}

strong,
b {
  font-family: "BrandonTextBold";
}
