.step2 {
  &__container {
    display: flex;
    align-items: center;
    background-color: $fittly-off-white;

    margin-bottom: 50px;
  }

  position: relative;
  overflow: hidden;

  h4 {
    font-family: "BrandonTextRegular";
  }

  &__item {
    &--container {
      display: flex;
      overflow: auto;
      gap: 5rem;
      padding: 2rem 0 2rem 0;
    }

    flex-shrink: 0;
    background-color: #fff;

    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 2rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;

    img {
      width: 200px;
      height: 273px;
      object-fit: cover;
      border-radius: 12px;
    }

    h5,
    p {
      padding-left: 1.5rem;
      transition: color 0.3s ease-in-out;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    h5 {
      padding-top: 1rem;
      max-width: 185px;
    }

    p {
      color: $fittly-dark-tan;
    }

    &:hover {
      transform: scale(1.01);
      box-shadow: 0.5rem 0.5rem 1rem rgba(#000, 0.1),
        -0.5rem -0.5rem 1rem rgba(#000, 0.1);

      // h5 {
      // }

      p {
        color: $fittly-orange;
        // color: $fittly-dark-teal;
      }
    }
  }
}

.titleStep2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #404148;
}

.subtitleStep2 {
  font-family: BrandonTextRegular;
  font-size: 18px;
  line-height: 27px;
  color: #213638;
}
