.appContainer{
  //max-width: 989px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.big-f-circle {
  background: rgb(245, 197, 109);
  background: linear-gradient(
    0deg,
    rgba(245, 197, 109, 1) 0%,
    rgba(240, 171, 112, 1) 100%
  );
  border-radius: 50%;
  width: 100vh;
  max-width: 989px;
  height: 100vh;
  //right: -50vw;
  right: -50%;
  position: absolute;

  @media (max-width: 600px) {
    display: none;
  }
}

.loading {
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($fittly-orange, 0.9);
  z-index: 9999;

  h1 {
    color: #fff;
    text-shadow: 2px 2px 5px #000;
  }
}

.nullTrainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field--null {
  background-color: #fff;
  height: 35rem;
  width: 100%;
  padding: 2rem 0;
  box-shadow: 0px 25px 35px rgba(19, 29, 29, 0.05);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "BrandonTextRegularItalic";
  &.step5-correction {
    width: 85vw;
  }
}
