.myExperience {
  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #404148;
  }

  h2 {
    font-family: BrandonTextRegular;
    font-size: 18px;
    line-height: 27px;
    color: #213638;
  }

  .boxHistory {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 25px 35px rgba(19, 29, 29, 0.05);
    width: 100%;
    padding: 50px !important;
    margin: 30px 0 30px 0 !important;

    .tab-pane {
      padding-top: 30px !important;
    }

    .nav-tabs {
      border: none;
      font-family: BrandonTextRegular;
    }

    .nav-link {
      font-weight: bold;
      font-size: 18px;
      color: #aea59b;
      margin-right: 20px !important;
      border: none;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #d17f5c;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 3px solid #d17f5c;
      padding-bottom: 10px !important;
    }

    /* Timeline holder */
    ul.timeline {
      list-style-type: none;
      position: relative;
      padding-left: 1.5rem;
    }

    /* Timeline vertical line */
    ul.timeline:before {
      content: " ";
      background: #eba14a;
      display: inline-block;
      position: absolute;
      left: 16px;
      width: 4px;
      height: 100%;
      z-index: 400;
      border-radius: 1rem;
    }

    li.timeline-item {
      margin: 35px !important;
    }

    /* Timeline item circle marker */
    li.timeline-item::before {
      content: " ";
      background: #eba14a;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #eba14a;
      left: 11px;
      width: 14px;
      height: 14px;
      z-index: 400;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
}

.spacer--fittly {
  padding: 12rem 0;
  text-align: center;
}
