.workoutPlaylist {
  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #404148;
  }

  h2 {
    font-family: BrandonTextRegular;
    font-size: 18px;
    line-height: 27px;
    color: #213638;
  }
}
