.step1 {
  h3,
  p {
    margin: 0;
  }
  &__container {
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      min-height: auto;
    }
  }

  display: flex;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  &__left {
    overflow: hidden;
    border-radius: 50px;

    img,
    video {
      object-fit: cover;
      object-position: 25%;
      height: 95vh;
      max-width: 650px;

      @media (max-width: 600px) {
        width: 100%;
        height: 75vh;
      }
    }

    @media (max-width: 600px) {
      width: 110%;
      flex-basis: 75%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      position: relative;
      top: 0px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: flex-start;
    padding: 5rem 0;
    padding-right: 5rem;
    padding-left: 5rem;

    @media (max-width: 768px) {
      align-items: center;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    @media (max-width: 600px) {
      padding-top: 0;
      margin-top: 0;
      flex-basis: 25%;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 30px;

    p span {
      font-family: "BrandonTextBold";
    }

    &--location {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-size: 16px;
        line-height: 24px;
        color: $fittly-dark-tan;
      }
    }
  }

  &__social-and-skills {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 600px) {
      position: absolute;
      height: 500px;
      top: 4%;
      left: 0;
      padding-left: 5%;
      padding-right: 5%;
      align-items: flex-start;
      flex-direction: column-reverse;
      box-sizing: border-box;
    }
  }

  &__social {
    &--container {
      display: flex;
      align-items: flex-end;

      @media (max-width: 600px) {
        align-self: flex-end;
        position: relative;
      }
    }
    display: flex;
    gap: 1rem;

    button {
      border: none;
      outline: none;
      background-color: $fittly-orange;
      transition: box-shadow 0.3s ease-in-out;
      margin-top: -50px;
      height: 50px;
      &:hover {
        box-shadow: 0.2rem 0.2rem 0.5rem rgba(#000, 0.2);
      }
    }

    p {
      color: #fff;
    }

    &--follow {
      padding: 0.5rem 2.5rem;
      display: flex;
      border-radius: 25px;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      img {
        width: 2rem;
      }
    }

    &--message {
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      display: grid;
      place-content: center;
    }
  }

  &__skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100px;
    gap: 0.75rem;
    margin-left: 20px;

    @media (max-width: 600px) {
      margin-left: 0px;
    }

    & > * {
      width: 100%;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }

    &--main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      img {
        width: 50px;
        height: 50px;
        object-fit: scale-down;
        background: rgba($fittly-dark-tan, 0.2);
        border-radius: 10px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  &--card {
    border-radius: 10px;
    box-shadow: 0.2rem 0.2rem 0.1rem rgba($fittly-dark-teal, 0.1),
      -0.2rem -0.1rem 0.1rem rgba($fittly-dark-teal, 0.05);
    padding: 0.5rem 2rem;
    background: $fittly-off-white;
  }
}

// @media (max-width: 600px) {
//   .app-container {
//     margin: 0;
//     padding: 0;
//   }
// }
