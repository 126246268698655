@import "~bootstrap/scss/bootstrap";
@import "./abstracts/fonts";
@import "./abstracts/variables";
@import "./components/globals";
@import "./components/app";
@import "./components/Landing/step1";
@import "./components/Landing/step2";
@import "./components/Landing/step3";
@import "./components/Landing/step4";
@import "./components/Landing/step5";
